@import "./src/scss/variables.scss";


.small__cards {
  display: flex;
  flex-direction:column;
  gap: 2.3rem;
  box-sizing: border-box;
  list-style: none;
  height: 100%;

  .card {
    padding: 2rem;
    align-items:center;
    flex-basis: 29%;
    display:flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 0.8rem;
    cursor: pointer;
    font-weight: 700;
    font-family: $title-font-family;
    min-height: 100px;
    position:relative;
  }
    
  a:hover {
    background-color:rgb(220, 220, 220);
  }
}

.small__cards.dark {
  a:hover {
    background-color:rgb(243, 243, 243);
    color:rgb(56, 56, 56);
  }

  a:hover > svg { // it's scoped so it will not work.
    color:rgb(56, 56, 56);
  }
}

@media (min-width: 660px) {
  .small__cards {
    gap: unset;
    justify-content: space-between;
    flex-flow:wrap;
  }
}

@media (min-width: 990px) {
  .small__cards {
    flex-flow:column;
    
    .card {
      min-width: 250px;
    }
  }
}
  
