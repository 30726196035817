$font-family: "Roboto", sans-serif;
$title-font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial,
sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
$title-color: #eff2ed;
$title-weight: 900;
$a-color:#cbfffa;

// light themed
$card-bg: rgb(250, 250, 250);
$card-text: #111111;;
$bg-color: rgb(230, 230, 230);
$font-colors: #333333;

// dark themed
$card-bg-dark: rgb(36, 36, 36);
$card-text-dark: snow;
$bg-color-dark: rgb(10, 10, 10);
$font-colors-dark: rgb(220, 220, 220);

//button
$button-color: rgb(29, 35, 48);
$button-border: 1px solid rgb(29, 35, 48);

$button-color-dark: white;
$button-bg-dark: transparent;
$button-border-dark: 1px solid white;