@import "./src/scss/variables.scss";

  .card-content {
    display: flex;
    flex-direction: column;
    gap: .8rem;
    text-align: left;

    .card-content-date {
      font-size: .7rem;
      font-weight:500;
    }
  }
