@import "./src/scss/variables.scss";


.skeleton {
    animation: skeleton-loading 1s linear infinite alternate;
    display: inherit;

    &__link {
      width: 360px;
    }

    &__image {
      height: 180px;
      width: 360px;
    }

    &__header {
      width: 75%;
      height: 0.5rem;
    }

    &__text {
      width: 100%;
      height: 0.7rem;
      margin-bottom: 0.5rem;
      border-radius: 0.25rem;
    }

    &__button {
      border-radius: 5px;
      width: 130px;
      height: 43px;
    }
}

@keyframes skeleton-loading {
  0% {
    background-color: hsl(200, 5%, 50%);
  }
  100% {
    background-color: hsl(200, 20%, 95%);
  }
}

